import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48')
];

export const server_loads = [0,3];

export const dictionary = {
		"/(marketing)": [8,[2]],
		"/app": [17,[3],[4]],
		"/app/color": [18,[3],[4]],
		"/app/coming-soon": [19,[3],[4]],
		"/app/dashboard": [20,[3],[4]],
		"/app/dashboard/notification/[notificationType]": [21,[3],[4]],
		"/app/global-admin": [22,[3],[4]],
		"/app/global-admin/customers": [23,[3],[4]],
		"/app/global-admin/customers/new": [25,[3],[4]],
		"/app/global-admin/customers/[customerId]": [24,[3],[4]],
		"/app/locates": [26,[3],[4]],
		"/app/locates/[locateRequestId]": [27,[3,5],[4]],
		"/app/locates/[locateRequestId]/utilities/[locateResponseId]/default": [~28,[3,5],[4]],
		"/app/locates/[locateRequestId]/utilities/[locateResponseId]/georgia-811": [~29,[3,5],[4]],
		"/app/messages": [30,[3,6],[4]],
		"/app/projects": [31,[3],[4]],
		"/app/projects/create": [~36,[3],[4]],
		"/app/projects/[projectId]": [32,[3],[4]],
		"/app/projects/[projectId]/add": [34,[3],[4]],
		"/app/projects/[projectId]/create": [~35,[3],[4]],
		"/app/projects/[projectId]/[toggleState]": [33,[3],[4]],
		"/app/settings": [~37,[3,7],[4]],
		"/app/settings/general": [38,[3,7],[4]],
		"/app/settings/general/reset-pw/[email]": [39,[3,7],[4]],
		"/app/settings/team": [40,[3,7],[4]],
		"/app/settings/team/add": [~41,[3,7],[4]],
		"/app/settings/team/edit/[teamMemberId]": [~42,[3,7],[4]],
		"/app/whats-new": [43,[3],[4]],
		"/authenticate/reset-pw": [~48],
		"/auth/magic-link": [~44],
		"/auth/reset-pw": [~45],
		"/auth/sign-in": [~46],
		"/auth/sign-out": [~47],
		"/(marketing)/blog/q-and-a-rosanna-laurent-leblanc": [9,[2]],
		"/(marketing)/cookies": [10,[2]],
		"/(marketing)/disclaimer": [11,[2]],
		"/(marketing)/firstfriday": [12,[2]],
		"/(marketing)/privacy": [13,[2]],
		"/(marketing)/sign-up": [~14,[2]],
		"/(marketing)/tos": [15,[2]],
		"/(marketing)/trial": [16,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';