import * as SentrySvelte from '@sentry/svelte';
import posthog from 'posthog-js';
import type { HandleClientError } from '@sveltejs/kit';

import config from '$lib/conf';

if (config.env !== 'local') {
	SentrySvelte.init({
		dsn: config.sentryDSN,
		environment: config.env,
		tracesSampleRate: 1.0
	});
}

if (config.posthogAPIKey) {
	posthog.init(config.posthogAPIKey, {
		api_host: 'https://us.posthog.com'
	});
}

export const handleError: HandleClientError = ({ error, event }) => {
	// const errorId = crypto.randomUUID();
	// const errorId = 'foobar';
	// add errorId to sveltekit context
	// contexts: { sveltekit: { event, errorId } }
	SentrySvelte.captureException(error, {
		contexts: { sveltekit: { event } }
	});

	return {
		message:
			'An unexpected error occurred. Our engineering team has been notified, but if this continues to persist, please contact support.'
	};
};
